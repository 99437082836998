import React from "react";
import "./App.css";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./views/Home";
// import Home2 from './components/Home2';
import CallScreen from "./views/CallScreen";
import { AppProvider } from "./context/AppContext";
import Navbar from "./components/Navbar";
import NotFound from "./views/NotFound";

const AppRouting = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Navbar />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/:username/:roomName",
        element: <CallScreen />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ]
  },
]);

function App() {
  return (
    <AppProvider>
      <div className="z-20 bg-gray-100 flex flex-col h-screen w-screen overflow-y-auto pt-20">
        <RouterProvider router={AppRouting} />
      </div>
    </AppProvider>
  );
}

export default App;
