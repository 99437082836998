import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Select, Option } from "@material-tailwind/react";
import AppContext from "../context/AppContext";

export default function Home() {
  const { room, setRoom } = useContext(AppContext);
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [listMissions, setListMissions] = useState<any>([
    {
      numMission: "23041OM2312046",
      assure: "M. MOHAMED BOUJNANE",
      marque: "MITSUBISHI",
      matricule: "16431-A-34",
      dateAccident: "2023-06-06T00:00:00",
    },
    {
      numMission: "23041OM2312045",
      assure: "STE GROUPE SCOLAIRE FATH AL BARI",
      marque: "IVECO",
      matricule: "14712-D-08",
      dateAccident: "2023-05-25T00:00:00",
    },
    {
      numMission: "23041OM2312043",
      assure: "M. AIT ZIAD ABDERRAZEK",
      marque: "FORD",
      matricule: "10078-A-73",
      dateAccident: "2023-06-09T00:00:00",
    },
    {
      numMission: "23041OM2312041",
      assure: "M. BOULAHCEN SOUHAIL",
      marque: "FORD",
      matricule: "07082-A-25",
      dateAccident: "2023-04-01T00:00:00",
    },
    {
      numMission: "23041OM2312036",
      assure: "M. EL MOUTAWAKIL AZIZ",
      marque: "SKODA",
      matricule: "76106-A-48",
      dateAccident: "2023-06-08T00:00:00",
    },
    {
      numMission: "23041OM2312042",
      assure: "Mlle DJEDAINI Naima ",
      marque: "RENAULT",
      matricule: "46834-B-72",
      dateAccident: "2023-06-11T04:00:00",
    },
    {
      numMission: "23041OM2312030",
      assure: "FAST MOTO",
      marque: "PEUGEOT",
      matricule: "03697-H-06",
      dateAccident: "2023-02-08T00:00:00",
    },
    {
      numMission: "23041OM2312028",
      assure: "M. ASSA ILYAS",
      marque: "DACIA",
      matricule: "19199-D-26",
      dateAccident: "2023-06-05T02:30:00",
    },
    {
      numMission: "23041OM2312027",
      assure: "MME OUKHISSI ZAHRA",
      marque: "PEUGEOT",
      matricule: "53262-A-20",
      dateAccident: "2023-05-31T00:00:00",
    },
    {
      numMission: "23041OM2312025",
      assure: "M. EL HAMZAOUI FATIHA",
      marque: "NISSAN",
      matricule: "54448-A-04",
      dateAccident: "2023-06-10T00:00:00",
    },
  ]);
  const [selectedMission, SetSelectedMission] = useState<any>({
    numMission: "23041OM2312046",
    assure: "M. MOHAMED BOUJNANE",
    marque: "MITSUBISHI",
    matricule: "16431-A-34",
    dateAccident: "2023-06-06T00:00:00",
  });

  function selectMission(event: any) {
    let mission: any = listMissions.find(
      (x: any) => x.numMission === event.target.value
    );
    SetSelectedMission(mission);
    setRoom?.(mission);
    console.log(mission);
  }

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(
        "https://server-test.expertisapi.ma/artific/Mission/ProcedureNormale/EnCours?skip=0&take=10",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "json",
        }
      )
      .then((res: any) => {
        console.log(res?.data);
        setListMissions(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  function decodeImgUrl(marque: string) {
    if (marque) {
      if (marque === "BMW") {
        return "../assets/images/marques/BMW.png";
      } else if (marque === "DONGFENG") {
        return "../assets/images/marques/DONG_FENG.png";
      } else if (marque === "DAIHATSU") {
        return "../assets/images/marques/Daihatsu_Logo.png";
      } else if (marque === "CHANA") {
        return "../assets/images/marques/Chana_Logo.png";
      } else if (marque === "ALFA ROMEO" || marque === "ALFA-ROMEO") {
        return "../assets/images/marques/AlfaRomeo.png";
      } else if (marque === "LAND ROVER" || marque === "LAND-ROVER") {
        return "../assets/images/marques/LandRover.png";
      } else if (marque === "MERCEDES-BENZ") {
        return "../assets/images/marques/Mercedes.png";
      } else if (marque === "ASTON MARTIN" || marque === "ASTON-MARTIN") {
        return "../assets/images/marques/ASTON_MARTIN.png";
      } else if (marque === "ROLLS-ROYCE") {
        return "../assets/images/marques/ROLLS_ROYCE.png";
      } else if (marque === "LEXUS") {
        return "../assets/images/marques/lexus_Logo.png";
      } else if (marque === "DFSK") {
        return "../assets/images/marques/DFSK_logo.png";
      } else if (marque === "TATA") {
        return "../assets/images/marques/tata_Logo.png";
      } else if (marque === "HUYNDAI") {
        return "../assets/images/marques/hyundai.png";
      } else if (marque === "GREAT-WALL") {
        return "../assets/images/marques/GREAT_WALL.png";
      } else {
        let lower = marque.toLowerCase();
        let first = lower.charAt(0).toUpperCase();
        let final = first + lower.slice(1);
        const url: string = "../assets/images/marques/" + final + ".png";
        return url;
      }
    }
  }

  return (
    <div className="grid md:grid-cols-3 w-full h-full place-items-center gap-10 md:p-10 md:px-20">
      <div className="hidden md:grid md:grid-rows-2 h-full w-full gap-6">
        <div className="relative grid place-items-center rounded-xl shadow-md p-3 bg-[url(assets/images/constateur.jpg)] bg-center bg-cover border border-gray-300">
          {/* <div className="absolute top-5 -right-3 flex items-center px-10 py-2 bg-gray-100 rounded-xl shadow-lg text-xl font-semibold">
            Constateur
          </div> */}
        </div>
        <div className="relative grid place-items-center rounded-xl shadow-md p-3 bg-[url(assets/images/expert.jpg)] bg-center bg-cover border border-gray-300">
          {/* <div className="absolute top-5 -right-3 flex items-center px-10 py-2 bg-gray-100 rounded-xl shadow-lg text-xl font-semibold">
            Expert
          </div> */}
        </div>
      </div>

      <div className="md:col-span-2 grid place-items-center w-full h-full rounded-xl shadow-md border border-gray-300 p-10">
        {isLoading ? (
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="grid plcae-items-center h-full w-full gap-6">
            <div className="grid md:flex items-center gap-3 md:gap-10 px-10">
              <label className="flex items-center text-xl font-semibold">
                Mission
              </label>
              <select
                onChange={selectMission}
                id="underline_select"
                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
              >
                {listMissions.map((item: any, i: number) => {
                  return (
                    <option key={i} value={item.numMission}>
                      {item?.marque + " (" + item?.matricule + ")"}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="grid md:grid-cols-3">
              <div className="md:col-span-2 grid">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  {/* <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Product name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Color
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Price
                      </th>
                    </tr>
                  </thead> */}
                  <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Num Mission
                      </th>
                      <td className="px-6 py-4">
                        {selectedMission?.numMission}
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Assuré
                      </th>
                      <td className="px-6 py-4">{selectedMission?.assure}</td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Marque
                      </th>
                      <td className="px-6 py-4">{selectedMission?.marque}</td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Matricule
                      </th>
                      <td className="px-6 py-4">
                        {selectedMission?.matricule}
                      </td>
                    </tr>
                    <tr className="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        dateAccident
                      </th>
                      <td className="px-6 py-4">
                        {selectedMission?.dateAccident}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="bg-white grid place-items-center w-full h-full p-3">
                <img
                  src={require("../assets/images/marques/BMW.png")}
                  alt=""
                  className=""
                />
              </div>
            </div>

            <div className="grid md:flex items-center justify-center gap-6 mt-6">
              <Link
                to={`/constateur/${selectedMission?.numMission}`}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                <i className="fa fa-sign-in mr-2"></i>
                Constateur
              </Link>
              <span className="hidden md:block text-xs font-semibold">OR</span>
              <Link
                to={`/expert/${selectedMission?.numMission}`}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                <i className="fa fa-sign-in mr-2"></i>
                Expert
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
