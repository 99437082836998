import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Router,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import socketio from "socket.io-client";
import AppContext from "../context/AppContext";

export default function CallScreen() {
  const { room } = useContext(AppContext);
  const [joined, setJoined] = useState<boolean>(false);
  const [moveDirection, setMoveDirection] = useState<any>(null);

  const params = useParams();

  const localUsername = params.username;
  const roomName: any = params.roomName;

  const [stream, setStream] = useState<any>(null);
  const [remoteUsername, setRemoteUsername] = useState<any>(null);

  const localVideoRef: any = useRef(null);
  const remoteVideoRef: any = useRef(null);

  let canvasScreenShot: any = useRef();
  const canvasDisplay: any = useRef();

  const socket = socketio("https://neo4j.expertis.ma/", {
    autoConnect: false,
  });

  let pc: any; // For RTCPeerConnection Object

  function fullScreen(e: any) {
    const elem = e.target;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  function takeScreenShot() {
    const ctx = canvasScreenShot.current.getContext("2d");
    canvasScreenShot.current.height = remoteVideoRef.current.videoHeight;
    canvasScreenShot.current.width = remoteVideoRef.current.videoWidth;
    ctx.drawImage(remoteVideoRef.current, 0, 0);
    // happens with the first image in Firefox; should wrap this in a videoElem.onplay
    canvasScreenShot.current.toBlob((blob: any) => {
      if (blob === null) {
        console.log("Failed to convert canvas to blob");
        return;
      } else {
        const a = document.createElement("a");
        a.download = "screenshot.jpg";
        a.href = URL.createObjectURL(blob);
        a.addEventListener("click", (e) => {
          setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
      }
    });
  }

  //====================================================================

  function join_room() {
    console.log("==> Joining room ...");
    socket.connect();
    socket.emit("join", { username: localUsername, room: roomName });
    setJoined(true);
  }
  socket.on("ready", (data) => {
    console.log("==> Remote User Ready to Connect!");
    console.log("remoteUser: ", data.username);
    setRemoteUsername(data.username);
    createPeerConnection();
    sendOffer();
    socket.connect();
    socket.emit("ready2", { username: localUsername, room: roomName });
  });
  socket.on("ready2", (data) => {
    setRemoteUsername(data.username);
  });

  function leaveCall() {
    console.log("==> Leaving Call ...");
    localVideoRef.current.srcObject = null;
    canvasDisplay.current.getContext("2d").clearRect(0, 0, 0, 0);
    setStream(null);
    setJoined(false);
    socket.connect();
    socket.emit("leave", { username: localUsername, room: roomName }, () => {
      window.location.reload();
      socket.disconnect();
      // pc.close();
    });
  }
  socket.on("left_room", (data: any) => {
    console.log("==> User left room : ", data.username);
    setRemoteUsername(null);
    remoteVideoRef.current.srcObject = null;
    canvasDisplay.current.getContext("2d").clearRect(0, 0, 0, 0);
    // pc.close();
    // window.location.reload();
  });

  socket.on("data", (data: any) => {
    console.log("Data received: ", data);
    signalingDataHandler(data);
  });

  socket.on("detection-response", (res) => {
    const preds = JSON.parse(res);
    console.log("Preds : ", preds);
    renderPredictions(preds, canvasDisplay.current);
  });

  const createPeerConnection = () => {
    console.log("==> Creating Peer ...");
    try {
      pc = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:openrelay.metered.ca:80",
          },
          {
            urls: "turn:openrelay.metered.ca:80",
            username: "openrelayproject",
            credential: "openrelayproject",
          },
          {
            urls: "turn:openrelay.metered.ca:443",
            username: "openrelayproject",
            credential: "openrelayproject",
          },
          {
            urls: "turn:openrelay.metered.ca:443?transport=tcp",
            username: "openrelayproject",
            credential: "openrelayproject",
          },
        ],
        // iceServers: [
        //   {
        //     urls: "stun:a.relay.metered.ca:80",
        //   },
        //   {
        //     urls: "turn:a.relay.metered.ca:80",
        //     username: "2df67d8a14720cf488dc2826",
        //     credential: "C268j/IbO+Ox4stu",
        //   },
        //   {
        //     urls: "turn:a.relay.metered.ca:80?transport=tcp",
        //     username: "2df67d8a14720cf488dc2826",
        //     credential: "C268j/IbO+Ox4stu",
        //   },
        //   {
        //     urls: "turn:a.relay.metered.ca:443",
        //     username: "2df67d8a14720cf488dc2826",
        //     credential: "C268j/IbO+Ox4stu",
        //   },
        //   {
        //     urls: "turn:a.relay.metered.ca:443?transport=tcp",
        //     username: "2df67d8a14720cf488dc2826",
        //     credential: "C268j/IbO+Ox4stu",
        //   },
        // ],
      });
      pc.onicecandidate = onIceCandidate;
      pc.ontrack = onTrack;
      const localStream = localVideoRef.current.srcObject;
      if (localVideoRef.current.srcObject) {
        for (const track of stream.getTracks()) {
          pc.addTrack(track, stream);
        }
      }
      console.log("PeerConnection created");
    } catch (error) {
      console.error("PeerConnection failed: ", error);
    }
  };

  const setAndSendLocalDescription = (sessionDescription: any) => {
    console.log("==> Setting Local description ...");
    pc.setLocalDescription(sessionDescription);
    sendData(sessionDescription);
  };

  const sendOffer = () => {
    console.log("==> Sending offer ...");
    pc.createOffer().then(setAndSendLocalDescription, (error: any) => {
      console.error("Send offer failed: ", error);
    });
  };

  const sendAnswer = () => {
    console.log("==> Sending answer ...");
    pc.createAnswer().then(setAndSendLocalDescription, (error: any) => {
      console.error("Send answer failed: ", error);
    });
  };

  const sendData = (data: any) => {
    socket.emit("data", {
      username: localUsername,
      room: roomName,
      data: data,
    });
  };

  const signalingDataHandler = (data: any) => {
    if (data.type === "offer") {
      createPeerConnection();
      pc.setRemoteDescription(new RTCSessionDescription(data));
      sendAnswer();
    } else if (data.type === "answer") {
      pc.setRemoteDescription(new RTCSessionDescription(data));
    } else if (data.type === "candidate") {
      pc.addIceCandidate(new RTCIceCandidate(data.candidate));
    } else {
      console.log("Unknown Data");
    }
  };

  const onIceCandidate = (event: any) => {
    if (event.candidate) {
      console.log("Sending ICE candidate");
      sendData({
        type: "candidate",
        candidate: event.candidate,
      });
    }
  };

  const onTrack = (event: any) => {
    console.log("Adding remote track");
    remoteVideoRef.current.srcObject = event.streams[0];

    // remoteVideoRef.current.srcObject = stream;
    if (true) {
      const ctx = canvasScreenShot.current.getContext("2d");
      setInterval(async () => {
        // I am not assuming the source video has fixed dimensions
        canvasScreenShot.current.height = remoteVideoRef.current.videoHeight;
        canvasScreenShot.current.width = remoteVideoRef.current.videoWidth;
        ctx.drawImage(remoteVideoRef.current, 0, 0);
        // happens with the first image in Firefox; should wrap this in a videoElem.onplay
        canvasScreenShot.current.toBlob((blob: any) => {
          if (blob === null) {
            console.log("Failed to convert canvas to blob");
            return;
          }
          socket.emit("detect", blob);
        });
      }, 1000);
    }
  };

  function renderPredictions(predictions: any, canvas: any) {
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // if (remoteVideoRef.current) {
    //   canvas.width = remoteVideoRef.current.width;
    //   canvas.height = remoteVideoRef.current.height;
    // }

    // options.
    const font = "10px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";
    // let Waspect = 1;
    // let Haspect = 1;
    let Waspect = canvas.width / 640;
    let Haspect = canvas.height / 640;

    predictions.forEach((prediction: any) => {
      const x = prediction.bbox[0] * Waspect;
      const y = prediction.bbox[1] * Haspect;
      const width = prediction.bbox[2] * Waspect;
      const height = prediction.bbox[3] * Haspect;
      // Draw the bounding box.
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 1;
      ctx.strokeRect(x, y, width, height);
      // Draw the label background.
      ctx.fillStyle = "#00FFFF";
      const textWidth = ctx.measureText(prediction.class).width + 2;
      const textHeight = parseInt(font, 6); // base 10
      ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
      // Draw the text last to ensure it's on top.
      ctx.fillStyle = "#000000";
      ctx.fillText(" " + prediction.class + " ", x, y);
    });
  }

  function order_move(direction: any) {
    socket.connect();
    socket.emit("order_move", {
      direction: direction,
      room: roomName,
      username: localUsername,
    });
  }
  socket.on("order_move", (data) => {
    console.log("Order Move :", data.direction);
    setMoveDirection(data.direction);
    setTimeout(function () {
      setMoveDirection(null);
    }, 500);
  });

  //====================================================================

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          width: window.innerWidth - 50,
          height: window.innerHeight - 100,
          facingMode: "environment",
        },
      })
      .then((stream) => {
        console.log("Local Stream found");
        setStream(stream);
        localVideoRef.current.srcObject = stream;
      })
      .catch((error) => {
        console.error("Stream not found: ", error);
      });
  }, []);

  useEffect(() => {
    console.log("localUsername : ", localUsername);
  }, [localUsername]);

  return (
    <div className="relative flex flex-col w-full h-full">
      {joined && (
        <div className="z-30 absolute bottom-5 grid md:flex items-center justify-between w-full bg-white bg-opacity-50 shadow-lg px-16 md:px-20 py-3 text-sm md:text-lg font-semibold">
          <div className="">Mission : {room?.numMission || "Undefined"}</div>
          <div className="">Marque : {room?.marque || "Undefined"}</div>
          <div className="">Matricule : {room?.matricule || "Undefined"}</div>
          <div className="">Assuré : {room?.assure || "Undefined"}</div>
        </div>
      )}

      <div
        className={`absolute top-0 grid place-items-center w-full h-full bg-gray-100 ${
          localUsername === "constateur" ? "z-20 opacity-100" : "z-0 opacity-0"
        }`}
      >
        <video
          className="cursor-pointer"
          autoPlay
          muted
          playsInline
          ref={localVideoRef}
          // onClick={fullScreen}
        />
        <div className="absolute w-full h-full p-20">
          <div className="relative grid place-items-center w-full h-full z-30">
            {moveDirection === "up" && (
              <i className="fa fa-up-long text-red-500 text-9xl" />
            )}
            {moveDirection === "down" && (
              <i className="fa fa-down-long text-red-500 text-9xl" />
            )}
            {moveDirection === "left" && (
              <i className="fa fa-left-long text-red-500 text-9xl" />
            )}
            {moveDirection === "right" && (
              <i className="fa fa-right-long text-red-500 text-9xl" />
            )}
          </div>
        </div>
      </div>
      <div
        className={`grid place-items-center w-full h-full bg-gray-100 p-3 ${
          localUsername === "expert" ? "z-20 opacity-100" : "z-0 opacity-0"
        }`}
      >
        <div className="relative grid place-items-center w-full h-full">
          <canvas
            className="canvasScreenShot w-full h-full"
            ref={canvasScreenShot}
          />
          <canvas className="canvasDisplay w-full h-full" ref={canvasDisplay} />
          <video
            className="cursor-pointer w-full h-full"
            autoPlay
            muted
            playsInline
            ref={remoteVideoRef}
            onClick={fullScreen}
          />
        </div>
        <div className="absolute w-full h-full p-20">
          <div className="relative w-full h-full z-30">
            <button onClick={() => order_move("up")} className="">
              <i className="absolute top-0 left-[calc(50%-20px)] fa fa-square-caret-up text-5xl text-blue-500"></i>
            </button>
            <button onClick={() => order_move("down")} className="">
              <i className="absolute bottom-0 left-[calc(50%-20px)] fa fa-square-caret-down text-5xl text-blue-500"></i>
            </button>
            <button onClick={() => order_move("left")} className="">
              <i className="absolute top-[calc(50%-20px)] left-0 fa fa-square-caret-left text-5xl text-blue-500"></i>
            </button>
            <button onClick={() => order_move("right")} className="">
              <i className="absolute top-[calc(50%-20px)] right-0 fa fa-square-caret-right text-5xl text-blue-500"></i>
            </button>
          </div>
        </div>
      </div>

      {!joined ? (
        <div className="z-30 absolute top-[calc(50%-17px)] left-[calc(50%-67px)]">
          <button
            onClick={() => join_room()}
            className="bg-white bg-opacity-50 shadow-lg rounded-full border border-gray-300 text-green-900 font-semibold px-6 py-2"
          >
            Join Mission
          </button>
        </div>
      ) : (
        <div className="z-30 absolute top-10 right-10 flex items-center gap-3">
          <button
            onClick={() => leaveCall()}
            className="order-2 grid place-items-center h-10 w-10 bg-white bg-opacity-50 rounded-full shadow-lg"
          >
            <i className="fa fa-close text-red-500 text-3xl font-extrabold"></i>
          </button>
          {localUsername === "expert" && (
            <button
              onClick={() => takeScreenShot()}
              className="order-1 grid place-items-center h-10 w-10 bg-white bg-opacity-50 rounded-full shadow-lg"
            >
              <i className="fa fa-camera-retro text-blue-500 text-2xl font-extrabold"></i>
            </button>
          )}
        </div>
      )}
    </div>
  );
}
