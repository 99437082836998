import * as React from "react";
import { useState } from "react";

// export interface IAppContext {
//   roomName: string;
//   setRoomName: (value: any) => void;
//   localUsername: string;
//   setLocalUsername: (value: any) => void;
//   stream: any;
//   setStream: (value: any) => void;
// }

const AppContext = React.createContext<Partial<any>>({});
export default AppContext;

export const AppProvider = ({ children }: any) => {
  const [room, setRoom] = React.useState<any>(null);
  const [localUsername, setLocalUsername] = React.useState<any>(null);

  return (
    <AppContext.Provider
      value={{
        room,
        setRoom,
        localUsername,
        setLocalUsername,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
